import { SliceZone } from '@prismicio/react';
import {
  FC,
  useEffect,
  useState,
  WheelEvent,
  useCallback,
  useRef,
} from 'react';
import { HorizontalLine, Layout, MetaData } from '../components';
import theme from '../theme';
import { useIsMobile } from '../utils/helpers';
import styles from '../styles/pages/home.module.scss';
import { Box, Typography } from '@mui/material';
import DesignRush from '../../public/designrush-dark.png';
import Image from 'next/image';
import { createClient } from '../prismicio';
import { components } from '../slices';
import { Transition, SwitchTransition } from 'react-transition-group';

type PageParams = { slices: any; menu: any; footer: any; data: MetaData };
let slideTimeoutHandler: ReturnType<typeof setTimeout> | undefined;
let lastScrollTime = new Date().getTime();

const Home: FC<PageParams> = ({ slices, menu, data, footer }) => {
  const [initialising, setInitialising] = useState(true);
  const [hijackScroll, setHijackScroll] = useState(false);
  const [activeSlice, setActiveSlice] = useState(0);
  const [sections, setSections] = useState<HTMLElement[]>(slices);
  const [belowSlideRendered, setBelowSlideRendered] = useState<boolean>(false);
  const [belowSlideOpacity, setBelowSlideOpacity] = useState<boolean>(false);
  const [finalDotActive, setFinalDotActive] = useState<boolean>(false);
  const scrollArea = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const nodeRef = useRef(null);

  const animationTiming = 300;
  const scrollMinThrottle = 1200;
  const scrollMaxThrottle = 1200;
  const finalSlideIndex = slices.length - 1;

  const reactToIncrement = useCallback(
    (newSlice: number) => {
      if (slideTimeoutHandler) {
        clearTimeout(slideTimeoutHandler);
        slideTimeoutHandler = undefined;
      }

      if (newSlice === finalSlideIndex) {
        setBelowSlideOpacity(false);

        slideTimeoutHandler = setTimeout(() => {
          setBelowSlideRendered(true);

          slideTimeoutHandler = setTimeout(() => {
            setBelowSlideOpacity(true);
          }, 100);
        }, 1000);
      }
    },
    [finalSlideIndex]
  );

  const reactToDecrement = useCallback(() => {
    if (slideTimeoutHandler) {
      clearTimeout(slideTimeoutHandler);
      slideTimeoutHandler = undefined;
    }

    if (activeSlice === finalSlideIndex) {
      setBelowSlideOpacity(false);

      slideTimeoutHandler = setTimeout(() => {
        setBelowSlideRendered(false);
      }, 1000);
    }
  }, [activeSlice, finalSlideIndex]);

  const incrementSlide = useCallback(() => {
    if (activeSlice < finalSlideIndex) {
      const newSlice = activeSlice + 1;

      setActiveSlice(newSlice);

      reactToIncrement(newSlice);
    }
  }, [activeSlice, finalSlideIndex, reactToIncrement]);

  const decrementSlide = useCallback(() => {
    if (activeSlice > 0) {
      if (!scrollArea.current) {
        return;
      }

      const boundingBox = scrollArea.current.getBoundingClientRect();

      const scrollY = boundingBox.y;

      if (!scrollY) {
        setActiveSlice(activeSlice - 1);

        reactToDecrement();
      }
    }
  }, [activeSlice, reactToDecrement, scrollArea]);

  useEffect(() => {
    const extendedSlices = slices.map((sl: any) => ({
      goToSlice: (sliceNumber: number) => {
        setActiveSlice(sliceNumber);

        if (sliceNumber > activeSlice) {
          reactToIncrement(sliceNumber);
        } else if (sliceNumber < activeSlice) {
          reactToDecrement();
        }
      },
      ...sl,
    }));

    if (hijackScroll) {
      setSections(
        extendedSlices.filter(
          (slice: any) => slice.primary.stepNumber === activeSlice
        )
      );
    } else {
      setSections(extendedSlices);
    }
  }, [activeSlice, hijackScroll, slices, reactToIncrement, reactToDecrement]);

  useEffect(() => {
    const keyPressHandler = (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        incrementSlide();
      }
      if (e.key === 'ArrowUp') {
        if (finalDotActive) {
          setFinalDotActive(false);
        }

        decrementSlide();
      }
    };
    if (hijackScroll && window.document) {
      window.document.addEventListener('keyup', keyPressHandler);
    }
    return () => {
      if (window.document) {
        window.document.removeEventListener('keyup', keyPressHandler);
      }
    };
  }, [
    slices.length,
    incrementSlide,
    decrementSlide,
    hijackScroll,
    finalDotActive,
  ]);

  useEffect(() => {
    const resizeHandler = () => {
      if (theme.breakpoints.values.md > window.innerWidth) {
        setHijackScroll(false);
        setActiveSlice(0);
        setBelowSlideRendered(false);
        setBelowSlideOpacity(false);
      } else {
        setHijackScroll(true);
      }
    };

    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (belowSlideRendered || !hijackScroll) {
      document.body.style.overflow = 'visible';
    } else {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [belowSlideRendered, hijackScroll]);

  const onWheel = (e: WheelEvent<HTMLDivElement>) => {
    if (!hijackScroll) {
      return;
    }

    var now = new Date().getTime();

    // if scrolled more than once within a time period
    if (now - lastScrollTime < scrollMaxThrottle) {
      // don't process scroll more than once within a time period
      if (now - lastScrollTime < scrollMinThrottle) {
        return;
      }
    }

    const didScrollDown = e.deltaY > 0;

    if (didScrollDown) {
      incrementSlide();
    } else {
      if (finalDotActive) {
        setFinalDotActive(false);
      }

      decrementSlide();
    }
    lastScrollTime = new Date().getTime();
  };

  useEffect(() => setInitialising(false), []);

  return (
    <Layout
      menu={menu}
      data={data}
      footer={
        (activeSlice === finalSlideIndex && belowSlideRendered) || isMobile
          ? footer
          : undefined
      }
      omitBackToTop={true}
      keepHeader={true}
    >
      <div className={styles.content} onWheel={onWheel} ref={scrollArea}>
        <SwitchTransition mode={'out-in'}>
          <Transition
            appear={true}
            exit={true}
            key={`slice-${activeSlice}-${initialising}`}
            timeout={animationTiming}
            nodeRef={nodeRef}
          >
            {(state) => {
              return (
                <>
                  {!!initialising ? (
                    <Box height='100vh' ref={nodeRef} />
                  ) : (
                    <SliceZone
                      slices={sections as any}
                      components={components}
                      context={{
                        ref: nodeRef,
                        state,
                        belowSlideRendered,
                        belowSlideOpacity,
                        hijackScroll,
                        finalDotActive,
                        setFinalDotActive,
                      }}
                    />
                  )}
                </>
              );
            }}
          </Transition>
        </SwitchTransition>
      </div>
      {!initialising && ((activeSlice === finalSlideIndex && belowSlideRendered) || isMobile) && (
        <section className={`light ${styles.partners}`}>
          <div>
            <Typography variant="h3">Our partners</Typography>

            <HorizontalLine />
          </div>
          <div>
            <a href="https://www.designrush.com/agency/profile/silicon-rhino">
              <Box
                position="relative"
                overflow="hidden"
                className={styles.contain}
                height="290px"
                width="217px"
              >
                <Image
                  src={DesignRush}
                  alt="Design Rush Verified Agency - Silicon Rhino"
                  fill
                  sizes="100vw"
                />
              </Box>
            </a>
          </div>
        </section>
      )}
    </Layout>
  );
};

type StaticProps = {
  preview: any;
  previewData: any;
};

export const createGetStaticProps: () => any = () => {
  return async function getStaticProps({
    preview = null,
    previewData = {},
  }: StaticProps) {
    const { ref = null } = previewData;
    const client = createClient();

    try {
      const doc = (await client.getSingle('home', {})) || {};
      const rawSlices = doc.data.slices;
      const totalSteps = rawSlices.length;
      const titles = rawSlices.map((slice: any) => slice.primary.step);
      const slices = rawSlices.map((slice: any, index: number) => {
        return {
          ...slice,
          primary: {
            ...slice.primary,
            stepNumber: index,
            totalSteps,
            titles,
          },
        };
      });
      return {
        props: {
          ...doc,
          error: null,
          preview,
          previewData,
          slices,
        },
      };
    } catch (e: any) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(`[next-slicezone] ${e.toString()}`);
      }
      return {
        props: {
          ref,
          error: e.toString(),
          slices: [],
          preview,
          previewData,
        },
      };
    }
  };
};

export const getStaticProps = createGetStaticProps();

export default Home;
